import Auth from "./../services/Auth";

const RF = {
  requireAuth(to, from, next) {
    if (!Auth.loggedIn()) {
      let returnTo = "&path=" + btoa(process.env.VUE_APP_ACCOUNT + "?ref=v2");

      window.location.href =
        process.env.VUE_APP_SITE + "/minha-conta/?ref=v2" + returnTo;
      // next({
      //   path: "/login",
      //   query: { redirect: to.fullPath },
      // });
    } else {
      next();
    }
  },
  logout(to, from, next) {
    Auth.logout();
    const ref = sessionStorage.getItem("ref");
    window.location.href = process.env.VUE_APP_SITE + "/logout?ref=" + ref;

    next();
    // next('/login')
  },
};

export default RF;
